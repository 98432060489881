// sass-lint:disable class-name-format
// sass-lint:disable no-color-literals
// sass-lint:disable quotes
@import '~assets/wmnds/vars';
@import '~assets/wmnds/mixins';

$offset-top: $size-lg;
$duration: 0.35s;

.busAreas-esri-map {
  // Styling for ui in the top right
  .esri-ui-top-right {
    flex-flow: column-reverse;

    .esri-zoom {
      border: 0;
      background: 0;
      box-shadow: none;
    }

    // locate and zoom buttons
    .esri-locate,
    .esri-zoom .esri-widget--button {
      width: 35px;
      height: 35px;
      margin-bottom: $size-sm;
      border-radius: 5px;
      box-shadow: none;

      &:focus {
        outline: 0;
        box-shadow: 0 0 0 2px #ffffff, 0 0 0 4px #9d5baf;
        -webkit-box-shadow: 0 0 0 2px #ffffff, 0 0 0 4px #9d5baf;
      }
    }

    // zoom buttons
    .esri-zoom .esri-widget--button {
      color: #ffffff;
      background-color: get-color(information);

      &:hover {
        background-color: get-color(information, 30, dark);
      }

      // make icons a bit bigger
      .esri-icon {
        font-size: 18px;
        font-weight: bold;
      }
    }

    // locate button
    .esri-locate {
      border: 1px solid get-color(primary);
      color: get-color(primary);
      background: none;

      &:hover {
        background-color: get-color(secondary, 70);
      }

      // custom locate icon
      .esri-icon-locate:before {
        content: '';
        display: block;
        width: 18px;
        height: 18px;
        background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg viewBox='0 0 256 256' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='m223.13 1.766-208.7 96.324c-24.079 11.238-16.053 46.555 9.632 46.555h88.3v88.298c0 25.684 35.317 33.716 46.556 9.632l96.325-208.7c8.026-19.27-12.845-40.14-32.11-32.109z' fill='%233c1053'/%3E%3C/svg%3E%0A");
      }
    }
  }
}

.esri-ui .esri-popup {
  &__main-container {
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
  }

  &__header {
    padding-right: $size-xsm;
    order: 1;
  }

  &__header-buttons {
    padding: 0;
  }

  &__footer {
    width: calc(100% - 52px);
    flex: 0 0 calc(100% - 52px);
  }

  &__inline-actions-container,
  &__inline-actions-container:only-child {
    width: 100%;
    margin-right: 0;

    .esri-popup__button {
      display: inline-flex;
      margin-top: 0;
      margin-bottom: 0;
      border-radius: 0;
      color: get-color(cta);
      font-family: $x-font-family;
      font-size: 1rem;
      font-weight: 700;
      text-decoration: underline;
      white-space: normal;
      align-items: center;

      .esri-popup__action-text {
        margin-left: $size-xsm;
      }

      .esri-popup__action-image {
        width: 30px;
        height: 30px;
        flex: 0 0 30px;
      }

      &:hover {
        color: get-color(cta, 30, dark);
        background: none;
      }

      &[aria-label='Zoom to'] {
        display: none;
      }
    }
  }

  .esri-icon-close {
    position: relative;
    width: 30px;
    height: 30px;
    color: get-color(primary);
    font-size: 30px;
    flex: 0 0 30px;
  }
}
