// Refer to https://create-react-app.dev/docs/adding-a-css-modules-stylesheet
// On how to use scss modules with React
// sass-lint:disable class-name-format
// sass-lint:disable no-color-literals
// sass-lint:disable quotes
// Import WMN Design System scss for use in this scss file
// Tilde (~) represents the root folder(src)
@import '~assets/wmnds/vars';
@import '~assets/wmnds/mixins';

.mapView {
  position: relative;
}

.mapView {
  max-height: 100vh;

  @media (min-width: $breakpoint-md) {
    position: sticky;
    top: 0;
  }

  // CSS hack to make the map container square
  &::before {
    content: '';
    display: block;
    top: 0;
    left: 0;
    width: 100%;
    padding-top: 100%;
  }
}

.mapContainer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: get-color(background);
}

// Esri map styles
$offset-top: $size-lg;
$duration: 0.35s;

.disruptions-esri-map {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: height $duration ease;
  background-color: #eeeeee;

  @media all and(min-width: 768px) {
    position: static;
    width: calc(100% - 414px); // 414px is the width of the tray
    float: right;
  }

  // Styling for ui in the top right
  .esri-ui-top-right {
    flex-flow: column-reverse;

    .esri-zoom {
      border: 0;
      background: 0;
      box-shadow: none;
    }

    // locate and zoom buttons
    .esri-locate,
    .esri-zoom .esri-widget--button {
      width: 35px;
      height: 35px;
      margin-bottom: $size-sm;
      border-radius: 5px;
      box-shadow: none;

      &:focus {
        outline: 0;
        box-shadow: 0 0 0 2px #ffffff, 0 0 0 4px #9d5baf;
        -webkit-box-shadow: 0 0 0 2px #ffffff, 0 0 0 4px #9d5baf;
      }
    }

    // zoom buttons
    .esri-zoom .esri-widget--button {
      color: #ffffff;
      background-color: get-color(information);

      &:hover {
        background-color: get-color(information, 30, dark);
      }

      // make icons a bit bigger
      .esri-icon {
        font-size: 18px;
        font-weight: bold;
      }
    }

    // locate button
    .esri-locate {
      border: 1px solid get-color(primary);
      color: get-color(primary);
      background: none;

      &:hover {
        background-color: get-color(secondary, 70);
      }

      // custom locate icon
      .esri-icon-locate:before {
        content: '';
        display: block;
        width: 18px;
        height: 18px;
        background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg viewBox='0 0 256 256' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='m223.13 1.766-208.7 96.324c-24.079 11.238-16.053 46.555 9.632 46.555h88.3v88.298c0 25.684 35.317 33.716 46.556 9.632l96.325-208.7c8.026-19.27-12.845-40.14-32.11-32.109z' fill='%233c1053'/%3E%3C/svg%3E%0A");
      }
    }
  }
}
